/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import useInView from '../hooks/useInView';

import { Box } from '@mui/material';
import { motion } from 'framer-motion';

import avatar from '../assets/HOME/03-leaderboard-2-pfp-1-BLAST.webp';
import gold from '../assets/HOME/03-leaderboard-3-top1.webp';
import silver from '../assets/HOME/03-leaderboard-3-top2.webp';
import bronze from '../assets/HOME/03-leaderboard-3-top3.webp';
import other from '../assets/HOME/03-leaderboard-3-top4-10.webp';

import './Leaderboard.css';

const AnimatedOnScroll = ({
  children,
  parentVariants,
  childVariants,
  ...props
}) => {
  const [ref, inView] = useInView({ threshold: 0.1 });

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="10px"
      sx={{ overflow: 'hidden' }}
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={parentVariants}
      {...props}
      component={motion.div}
    >
      {React.Children.map(children, (child, index) => (
        <motion.div key={index} variants={childVariants}>
          {child}
        </motion.div>
      ))}
    </Box>
  );
};

const Entry = ({ data, index, variants }) => {
  const medals = [gold, silver, bronze, other];
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="10px"
      component={motion.div}
      variants={variants}
    >
      <div className={`${data.chain}_avatar`}></div>
      <div
        className={
          index < 3 ? `entry_${index} flex_between` : 'entry_3 flex_between'
        }
      >
        <div>{`${data.address.slice(0, 4)}...${data.address.slice(
          data.address.length - 4,
          data.address.length
        )}`}</div>
        <div>{data.points}</div>
      </div>
    </Box>
  );
};

const Leaderboard = () => {
  const [ref, inView] = useInView({ threshold: 0.1 });
  const [entries, setentries] = useState([
    { address: '0xhnd7sdhj3jh3ksk8sd83j3jsdsd', points: 21370, chain: 'blast' },
    {
      address: '0x222d7sdhj3jh3ksk8sd83j3jsaassda',
      points: 20420,
      chain: 'blast',
    },
    {
      address: '0xffdd7sdhj3jh3ksk8sd83j3jshghjj',
      points: 19690,
      chain: 'blast',
    },
    {
      address: '0xffdd7sdhj3jh3ksk8sd83j3jshghjj',
      points: 19690,
      chain: 'blast',
    },
    {
      address: '0xffdd7sdhj3jh3ksk8sd83j3jshghjj',
      points: 19690,
      chain: 'blast',
    },
    {
      address: '0xffdd7sdhj3jh3ksk8sd83j3jshghjj',
      points: 19690,
      chain: 'blast',
    },
    { address: '0xhnd7sdhj3jh3ksk8sd83j3jsdsd', points: 21370, chain: 'blast' },
    {
      address: '0x222d7sdhj3jh3ksk8sd83j3jsaassda',
      points: 20420,
      chain: 'blast',
    },
    {
      address: '0xffdd7sdhj3jh3ksk8sd83j3jshghjj',
      points: 19690,
      chain: 'blast',
    },
    {
      address: '0xffdd7sdhj3jh3ksk8sd83j3jshghjj',
      points: 19690,
      chain: 'blast',
    },
  ]);

  const [active_runners, setactive_runners] = useState(103);
  const [total_runs, settotal_runs] = useState(2137);
  const [prize_pool, setprize_pool] = useState(12.345);

  const [isOpen, setisOpen] = useState(false);

  const open_leaderboard = () => {
    setisOpen(!isOpen);
  };

  const variants = {
    hidden_x: { opacity: 0, x: -20 },
    visible_x: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: 0.0,
        ease: 'easeOut',
        type: 'spring',
        stiffness: 100,
        damping: 10,
      },
    },
    hidden_x2: { opacity: 0, x: 20 },
    visible_x2: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: 0.0,
        ease: 'easeOut',
        type: 'spring',
        stiffness: 100,
        damping: 10,
      },
    },
    hidden_y: { opacity: 0, y: -20 },
    visible_y: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.0,
        ease: 'easeOut',
        type: 'spring',
        stiffness: 100,
        damping: 10,
      },
    },
  };
  const parentVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  return (
    <>
      <motion.div
        className="leaderboard_topbar"
        ref={ref}
        initial="hidden_y"
        animate={inView ? 'visible_y' : 'hidden_y'}
        variants={variants}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <p>
            THIS WEEK`S LEADERBOARD{' '}
            <span style={{ color: '#fdfe03' }}>[WEEK #21]</span> 05/20 - 05/26{' '}
          </p>
          <p>ENDS IN ...</p>
        </Box>
      </motion.div>
      <Box
        display="flex"
        flexDirection="row"
        height={isOpen ? '850px' : '260px'}
        marginTop="20px"
        gap="20px"
        transition="height 300ms"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="10px"
          sx={{ overflow: 'hidden' }}
          ref={ref}
          initial="hidden_x"
          animate={inView ? 'visible_x' : 'hidden_x'}
          variants={parentVariants}
          component={motion.div}
        >
          {entries.map((data, index) => {
            if (isOpen) {
              return <Entry data={data} index={index} variants={variants} />;
            } else {
              if (index < 3) {
                return <Entry data={data} index={index} variants={variants} />;
              }
            }
            return false;
          })}
        </Box>
        <motion.div
          className="prize_pool"
          ref={ref}
          initial="hidden_x2"
          animate={inView ? 'visible_x2' : 'hidden_x2'}
          variants={variants}
        >
          <div>
            <p className="grey_txt">
              ACTIVE RUNNERS{' '}
              <span style={{ color: '#fff' }}>{active_runners}</span>
            </p>
            <p className="grey_txt">
              TOTAL RUNS <span style={{ color: '#fff' }}>{total_runs}</span>
            </p>
          </div>
          <p style={{ fontSize: '26px' }}>
            PRIZE POOL{' '}
            <span style={{ color: '#fdfe03' }}>{prize_pool} ETH</span>
          </p>
        </motion.div>
      </Box>
      <div className="leaderboard_open">
        <motion.div
          className={isOpen ? 'open' : 'closed'}
          onClick={open_leaderboard}
          whileHover={{
            rotate: [0, 2, -2, 2, -2, 0],
            transition: { duration: 0.4, ease: 'easeInOut' },
          }}
        ></motion.div>
      </div>
    </>
  );
};

export default Leaderboard;
