import React from 'react';

import { useNavigate } from 'react-router-dom';
import useInView from '../hooks/useInView';

import { Box } from '@mui/material';
import { motion } from 'framer-motion';

import meet from '../assets/HOME/04-menu-1-roster.webp';
import meet_hover from '../assets/HOME/04-menu-1-roster_HOV.webp';
import mech from '../assets/HOME/04-menu-2-mech.webp';
import mech_hover from '../assets/HOME/04-menu-2-mech_HOV.webp';
import rewards from '../assets/HOME/04-menu-3-rewards.webp';
import rewards_hover from '../assets/HOME/04-menu-3-rewards_HOV.webp';
import about from '../assets/HOME/04-menu-4-about.webp';
import about_hover from '../assets/HOME/04-menu-4-about_HOV.webp';

const AnimatedOnScroll = ({
  children,
  parentVariants,
  childVariants,
  ...props
}) => {
  const [ref, inView] = useInView({ threshold: 0.1 });

  return (
    <Box
      display="flex"
      flexDirection="row"
      marginTop="20px"
      gap="20px"
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={parentVariants}
      {...props}
      component={motion.div}
    >
      {React.Children.map(children, (child, index) => (
        <motion.div key={index} variants={childVariants}>
          {child}
        </motion.div>
      ))}
    </Box>
  );
};

const Navigation = () => {
  const navigate = useNavigate();
  const [ref, inView] = useInView({ threshold: 0.1 });

  const wiggleVariants = {
    hover: {
      rotate: [0, 2, -2, 2, -2, 0],
      transition: { duration: 0.4, ease: 'easeInOut' },
    },
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        type: 'spring',
        stiffness: 100,
        damping: 10,
      },
    },
  };
  const parentVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  return (
    // <Box display="flex" flexDirection="row" marginTop="20px" gap="20px">
    <AnimatedOnScroll
      parentVariants={parentVariants}
      childVariants={wiggleVariants}
    >
      <motion.img
        src={meet}
        alt="rooster"
        style={{ cursor: 'pointer' }}
        onMouseOver={(e) => (e.currentTarget.src = meet_hover)}
        onMouseOut={(e) => (e.currentTarget.src = meet)}
        onClick={() => {
          navigate('/rooster');
        }}
        variants={wiggleVariants}
        whileHover="hover"
        ref={ref}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      ></motion.img>
      <motion.img
        src={mech}
        alt="mech"
        style={{ cursor: 'pointer' }}
        onMouseOver={(e) => (e.currentTarget.src = mech_hover)}
        onMouseOut={(e) => (e.currentTarget.src = mech)}
        onClick={() => {
          navigate('/mechanics');
        }}
        variants={wiggleVariants}
        whileHover="hover"
        ref={ref}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      ></motion.img>
      <motion.img
        src={rewards}
        alt="rewards"
        style={{ cursor: 'pointer' }}
        onMouseOver={(e) => (e.currentTarget.src = rewards_hover)}
        onMouseOut={(e) => (e.currentTarget.src = rewards)}
        onClick={() => {
          navigate('/rewards');
        }}
        variants={wiggleVariants}
        whileHover="hover"
        ref={ref}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      ></motion.img>
      <motion.img
        src={about}
        alt="about"
        style={{ cursor: 'pointer' }}
        onMouseOver={(e) => (e.currentTarget.src = about_hover)}
        onMouseOut={(e) => (e.currentTarget.src = about)}
        onClick={() => {
          navigate('/about');
        }}
        variants={wiggleVariants}
        whileHover="hover"
        ref={ref}
        initial="hidden"
        animate={inView ? 'visible' : 'hidden'}
      ></motion.img>
    </AnimatedOnScroll>
  );
};

export default Navigation;
