/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Box, IconButton, useMediaQuery } from '@mui/material';

import { Outlet } from 'react-router-dom';
import Appbar from './Appbar';
import footer from '../assets/HOME/05-bottom-3-footer.webp';

const Layout = () => {
  const isNonMobile = useMediaQuery('(min-width: 600px)');
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          display={isNonMobile ? 'flex' : 'block'}
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems="center"
        >
          <Appbar />
          <Outlet />

          <img
            src={footer}
            alt="hero"
            width="100%"
            style={{ zIndex: '-1', width: '100%', margin: '-500px 0px' }}
          ></img>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
