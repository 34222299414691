import React from 'react';

import Navigation from '../components/Navigation';

const Rooster = () => {
  return (
    <>
      <Navigation />
    </>
  );
};

export default Rooster;
