/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Box } from '@mui/material';

import { delay, motion } from 'framer-motion';

import Carousel from '../components/Carousel';
import Leaderboard from '../components/Leaderboard';
import Navigation from '../components/Navigation';
import useInView from '../hooks/useInView';

import './Main.css';

import hero from '../assets/HOME/05-bottom-1-SLOGAN.webp';
import play from '../assets/HOME/05-bottom-2-PLAY.webp';
import play_hover from '../assets/HOME/05-bottom-2-PLAY_hover.webp';

const Main = () => {
  const [ref, inView] = useInView({ threshold: 0.1 });

  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay: 0.2,
        ease: 'easeOut',
        type: 'spring',
        stiffness: 100,
        damping: 10,
      },
    },
    hidden_play: { opacity: 0, scale: 0 },
    visible_play: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
        delay: 0.3,
        ease: 'easeOut',
        type: 'spring',
        stiffness: 100,
        damping: 10,
      },
    },
  };

  return (
    <Box display="flex" flexDirection="column">
      <Carousel />
      <Leaderboard />
      <Navigation />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="300px"
      >
        <motion.img
          src={hero}
          alt="hero"
          ref={ref}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
          variants={variants}
        ></motion.img>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="300px"
      >
        <motion.img
          src={play}
          alt="play"
          onMouseOver={(e) => (e.currentTarget.src = play_hover)}
          onMouseOut={(e) => (e.currentTarget.src = play)}
          style={{ zIndex: '999', cursor: 'pointer' }}
          ref={ref}
          initial="hidden"
          animate={inView ? 'visible_play' : 'hidden_play'}
          variants={variants}
        ></motion.img>
      </Box>
    </Box>
  );
};

export default Main;
