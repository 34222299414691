/* eslint-disable no-unused-vars */
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import Layout from './components/Layout';
import Main from './Pages/Main';
import Rooster from './Pages/Rooster';
import Mechanics from './Pages/Mechanics';
import Rewards from './Pages/Rewards';
import About from './Pages/About';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <CssBaseline />
        <Routes>
          <Route element={<Layout />}>
            {/* <Route path="generate" element={<Generate />} /> */}
            <Route path="" element={<Main />} />
            <Route path="rooster" element={<Rooster />} />
            <Route path="mechanics" element={<Mechanics />} />
            <Route path="rewards" element={<Rewards />} />
            <Route path="about" element={<About />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
